import React from "react";
import Layout from "./src/layouts";
// import { StoreProvider } from "./src/context/storeContext";
import { Toaster } from "react-hot-toast";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./src/assets/scss/style.scss";
import "./src/styles/globals.css";
import "./src/styles/tailwind.css";
export const wrapPageElement = ({ element, props }) => {
  return (
    <div>
      <Layout {...props}>{element}</Layout>;
      <Toaster />
    </div>
  );
};
